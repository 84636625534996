<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <curfox-logo />

          <h2 class="brand-text text-primary ml-1">
            Curfox
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Welcome to Curfox! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          The account has been created successfully. A verification OTP has been sent to your email. Enter the OTP to verify your account.
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="otpForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="verifyOTP"
          >
            <!-- email -->
            <b-form-group
              label-for="otp"
              label="Enter OTP"
            >
              <validation-provider
                #default="{ errors }"
                name="OTP"
                rules="required"
              >
                <b-form-input
                  id="otp"
                  v-model="otpcode"
                  name="otp"
                  :state="errors.length > 0 ? false:null"
                  placeholder="1 2 3 4 5 6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Verify
            </b-button>
            <div class="d-flex justify-content-between">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-info"
                @click="resendOTP"
              >
                <small>Resend OTP?</small>
              </b-button>
              <b-link :to="{name:'Register'}">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-info"
                >
                  <small>Change Email?</small>
                </b-button>
              </b-link>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText,
} from 'bootstrap-vue'
import CurfoxLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Ripple from 'vue-ripple-directive'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const AuthRepository = RepositoryFactory.get('auth')

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    CurfoxLogo,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, SuccessMessage, ErrorMessage],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      required,
      email,
      otpcode: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async verifyOTP() {
      try {
        const payload = {
          email: localStorage.getItem('email'),
          otp: this.otpcode,
        }
        const res = await AuthRepository.otp(payload)
        if (res.status === 201) {
          // OTP verification successful
          this.showSuccessMessage(res.data.message)
          this.$router.push('/buisness-details')
        } else {
          // OTP verification failed
          this.showErrorMessage(res.data.message)
        }
      } catch (error) {
        // Handle API error
        if (error.response && error.response.data && error.response.data.message) {
          this.showErrorMessage(error.response.data.message)
        } else {
          this.showErrorMessage('An error occurred while verifying OTP.')
        }
      }
    },
    async resendOTP() {
      try {
        const payload = {
          email: localStorage.getItem('email'),
        }
        const res = await AuthRepository.resendOtp(payload) // API call to resend OTP
        if (res.status === 201) {
          // OTP resent successfully
          this.showSuccessMessage(res.data.message)
        } else {
          this.showErrorMessage(res.data.message)
        }
      } catch (e) {
        this.showErrorMessage('An error occurred while resending OTP.')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
